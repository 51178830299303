import React, { Component, Suspense, useEffect, } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRoutes, HashRouter, useLocation } from 'react-router-dom'
import { MDBSpinner } from 'mdb-react-ui-kit'
import './scss/style.scss'
import { ToastContainer } from 'react-toastify'
import {
  decodeToken,
} from "src/api/user"
import { clearUserData } from './state/user'
// routes config
import routes from './routes'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const App = props => {
  const dispatch = useDispatch()
  const {
    authenticated
  } = useSelector(state => state.user)
  const location = useLocation()
  useEffect(() => {
    const token = localStorage.getItem("token")
    if( !token ) {
      dispatch(clearUserData())
      return
    }
    const { exp } = decodeToken(token)
    if( Math.round(new Date().getTime() / 1000) > exp ) {
      dispatch(clearUserData())
    }
  }, [location])

  return useRoutes(routes(authenticated))
}

const AppWrapper = props => {
  return (
    <QueryClientProvider client={ queryClient }>
      <HashRouter basename="/">

        <Suspense fallback={<div className='h-100 d-flex align-items-center justify-content-center'><MDBSpinner role='status'>
          <span className='visually-hidden'>Loading...</span>
        </MDBSpinner></div>}>

          <App className='container-fluid'>
            <ToastContainer />
          </App>
        </Suspense>
      </HashRouter >
    </QueryClientProvider>
  )
}

export default AppWrapper
