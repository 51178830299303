const {
    REACT_APP_API_URL,
    REACT_APP_API_PORT,
} = process.env

const API_PATH = `${REACT_APP_API_URL}:${REACT_APP_API_PORT}`

export const call = async ({ endpoint, method, body, params }) => {
    const url = params ? endpoint + "?" + new URLSearchParams(params).toString() : endpoint
    const token = localStorage.getItem("token") || ""
    const res = await fetch(
        `${API_PATH}${url}`, {
        headers: {
            Accept: `application/json`,
            'Content-Type': `application/json`,
            'Authorization': `Bearer ${token}`,
        },
        method,
        body: JSON.stringify(body)
    }
    )
    const data = await res.json()
    return {
        status: res.status,
        data,
    }
}

export const buildURL = endpoint => `${API_PATH}${endpoint}`