import { createSlice } from '@reduxjs/toolkit'

const navSlice = createSlice({
    name: 'nav',
    initialState: {
        sidebarShow: true,
        asideShow: false,
        theme: 'default',
    },
    reducers: {
        changeNavState: (state, action) =>  ({ ...state, ...action.payload })
    },
})


export const {
    changeNavState,
} = navSlice.actions

export default navSlice.reducer




