import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    authenticate,
    decodeToken,
} from "src/api/user"
const initialState = {
    email: null,
    firstName: null,
    lastName: null,
    authenticated: false,
    jwt: null,
    authFailReason: null,
}

const userTokenToState = token => {
    const {
        employee_number: employeeNumber,
        username,
        user_level: userLevel,
        firstname,
        lastname,
    } = decodeToken(token)
    return {
        employeeNumber,
        username,
        userLevel,
        firstname,
        lastname,
        token,
        authenticated: true,
    }
}

export const authenticateUser = createAsyncThunk(
    'user/authenticate',
    async ({ email, password }, thunkAPI) => {
        try {
            const response = await authenticate(email, password)
            if (response.status === 200) {
                return response.data
            } else {
                throw new Error(response.data)
            }

        } catch (error) {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserData(state, action) {
            const token = action.payload
            return userTokenToState(token)
        },
        clearUserData(state, action) {
            return initialState
        },
        clearAuthFailReason(state, action) {

            return {
                ...state,
                authFailReason: null
            }
        }
    },
    extraReducers: builder => {
        builder
            .addCase(authenticateUser.fulfilled, (state, action) => {
                const result = action.payload;
                const { token } = result;
                localStorage.setItem("token", token);
                return userTokenToState(token);
            })
            .addCase(authenticateUser.rejected, (state, action) => {
                state.authenticated = false;
                state.authFailReason = action.payload;
            });
    }
})


export const {
    setUserData,
    clearUserData,
    clearAuthFailReason
} = userSlice.actions

export default userSlice.reducer