/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import { Navigate } from 'react-router-dom'

// examples
const Login = React.lazy(() => import('./views/external/Login'))
const RiderAlerts = React.lazy(() => import('./views/dispatch/RiderAlerts'))
const NoShows = React.lazy(() => import('./views/accounting/NoShows'))
const CustomerSearch = React.lazy(() => import('./views/customers/CustomerSearch'))
const SeatManagement = React.lazy(() => import('./views/maint/SeatManagement'))
const TripManagement = React.lazy(() => import('./views/maint/TripManagement'))
const TripStopManagement = React.lazy(() => import('./views/maint/TripStopManagement'))
const ReservationManagement = React.lazy(() => import('./views/maint/ReservationManagement'))
const TripCapacityManagement = React.lazy(() => import('./views/maint/TripCapacityManagement'))
const IftaMilesReport = React.lazy(() => import('./views/reports/IftaReport'))
const DriverMilesReport = React.lazy(() => import('./views/reports/DriverMileageReport'))
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const CustomerProfile = React.lazy(() => import('./views/customers/CustomerProfile'))
const UserProfile = React.lazy(() => import('./views/users/UserProfile'))
const Seats = React.lazy(() => import('./views/reservations/Seats'))
const Users = React.lazy(() => import('./views/users/Users'))
const Incidents = React.lazy(() => import('./views/customers/Incidents'))
const NewUser = React.lazy(() => import('./views/users/NewUser'))
const Manifests = React.lazy(() => import('./views/accounting/Manifests/Manifests'))
const Manifest = React.lazy(() => import('./views/accounting/Manifests/Manifest'))
const NewManifest = React.lazy(() => import('./views/accounting/Manifests/NewManifest'))
const TripEditor = React.lazy(() => import('./views/maint/TripEditor'))
const TripAvailability = React.lazy(() => import('./views/dispatch/TripAvailability'))
const TripManagementMockup = React.lazy(() => import('./views/maint/TripManagementMockup'))

const FareScheduleManagerLines = React.lazy(() => import('./views/fsm/Lines.js'))
const FareScheduleManagerSchedule = React.lazy(() => import('./views/fsm/Schedule.js'))
const FareScheduleManagerTrips = React.lazy(() => import('./views/fsm/Trips.js'))
const FareScheduleManagerTemplateManagement = React.lazy(() => import('./views/fsm/Templates.js'))
const FareScheduleManagerTemplateView = React.lazy(() => import('./views/fsm/TemplateView.js'))
const FareScheduleManagerWeeklyOverrideView = React.lazy(() => import('./views/fsm/WeeklyOverrideView.js'))

export default isLoggedIn => ([
  {
    path: '/',
    element: !isLoggedIn ? <Login /> : <Navigate to="/app" />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '/', element: <Navigate to="/login" /> },
    ],
  },
  {
    path: '/app',
    element: isLoggedIn ? <DefaultLayout /> : <Navigate to="/login" />,
    children: [
      { path: '/app', element: <TripAvailability /> },
      { path: '/app/rider-alerts', element: <RiderAlerts /> },
      { path: '/app/users', element: <Users /> },
      { path: '/app/accounting', element: <NoShows /> },
      { path: '/app/accounting/no-shows', element: <NoShows /> },
      { path: '/app/accounting/manifests', element: <Manifests /> },
      { path: '/app/accounting/manifests/manifest/:manifest', element: <Manifest /> },
      { path: '/app/accounting/manifests/new-manifest/:manifest/:date', element: <NewManifest /> },
      { path: '/app/customers', element: <CustomerSearch /> },
      { path: '/app/seats', element: <Seats /> },
      { path: '/app/customers/profile/:custno', element: <CustomerProfile /> },
      { path: '/app/customers/incidents', element: <Incidents /> },
      { path: '/app/users/profile/:user', element: <UserProfile /> },
      { path: '/app/users/new-user', element: <NewUser /> },
      { path: '/app/trip-availability', element: <TripAvailability /> },
      { path: '/app/ifta-miles', element: <IftaMilesReport /> },
      { path: '/app/driver-miles', element: <DriverMilesReport /> },
      { path: '/app/maint/seat-management', element: <SeatManagement /> },
      { path: '/app/maint/reservations', element: <ReservationManagement /> },
      { path: '/app/maint/trip-management', element: <TripManagement /> },
      { path: '/app/maint/trip-stop-management', element: <TripStopManagement /> },
      { path: '/app/maint/trip-capacity-management', element: <TripCapacityManagement /> },
      { path: '/app/maint/trip-editor', element: <TripEditor /> },
      { path: '/app/maint/trip-builder-mockup', element: <TripManagementMockup /> },

      { path: '/app/fsm/lines', element: <FareScheduleManagerLines /> },
      { path: '/app/fsm/trips', element: <FareScheduleManagerTrips /> },
      { path: '/app/fsm/templates', element: <FareScheduleManagerTemplateManagement /> },
      { path: '/app/fsm/templates/:id', element: <FareScheduleManagerTemplateView /> },
      { path: '/app/fsm/schedule', element: <FareScheduleManagerSchedule /> },
      { path: '/app/fsm/overrides/:date', element: <FareScheduleManagerWeeklyOverrideView /> },
    ],
  },
])
