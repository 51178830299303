import {
    call
} from './index'
import { jwtDecode as decode } from 'jwt-decode';

export const authenticate = async (email, password) =>
    await call({
        endpoint: `/users/login`,
        method: `POST`,
        body: {
            username: email,
            password,
        }
    })

export const decodeToken = token => decode(token)


